import { SCHOOL_DETAILS, TENECE_SUPPORT_NUMBER } from "../../utils/constants";
import "./footer.css";
const { name } = SCHOOL_DETAILS;

export const Footer = ({ noCopyRight }) => {
	const date = new Date();
	return (
		<div className="footer_container d-flex flex-column align-items-center justify-content-center">
			<p className="call_centre_number">
				Need help? <span>-</span> <span>{TENECE_SUPPORT_NUMBER}</span>
			</p>
			{!noCopyRight && (
				<p className="text-center">
					{`Copyright ©️ ${date.getFullYear()} All rights reserved by ${name}. Powered by Tenece`}
				</p>
			)}
		</div>
	);
};
