import React from "react";
import logo from "../../assets/images/sideLogo.png";
import christmasLogo from "../../assets/images/christmasLogo.png";
import christmasLight from "../../assets/images/bannerLight.png";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";
import styles from "./auth_style.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function AuthPageWrapper({ children }) {
	return (
		<div className={`${styles.auth_container}`}>
			<div className={`${styles.auth_card} bg-white`}>
				{checkIfChristmasPeriod() && (
					<div className={styles.christmasLightContainer}>
						<img
							alt="christmas Light"
							className={styles.light}
							src={christmasLight}
						/>
					</div>
				)}
				<div className={`mt-5 pt-2 ${styles.logo}`}>
					<Link to="/">
						<img
							src={
								checkIfChristmasPeriod() ? christmasLogo : logo
							}
							alt="CCU Logo"
							className={`${styles.loginLogo}`}
						/>
					</Link>
				</div>
				{children}
			</div>
		</div>
	);
}
